import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { Category } from '../../contracts/response-models/category';
import { DeleteCategoryService } from '../../machines/admin/services'; 
interface CategoryProps{
    categoryInfo: Category; 
    setReadyState:any;
    setDisplayAlert:any;
    setAlertMessage:any;
    setLoading:any;
  }

function CategoryRow({categoryInfo,setReadyState,setDisplayAlert,setAlertMessage,setLoading}:CategoryProps) { 
 
  const handleConfirmDelete = async (pid: any) => {
    setLoading(true);
    if (pid > 0) { 
      const rsp = await DeleteCategoryService(pid); 
      setDisplayAlert(true);
      setAlertMessage("Category Deleted Successfully.")
      setTimeout( () => {  setDisplayAlert(false) 
      } , 2000);
      
      setReadyState(true);
      setLoading(false);
    }
  }
  
  return (
    <tr>
     
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
      <Link to={`/category-preview?id=${categoryInfo.id}`} > {categoryInfo.title!=undefined && categoryInfo.title.length>50 ?(categoryInfo.title.substring(1,50)+"..."):(categoryInfo.title)}</Link> 
      </td> 
   
      <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {categoryInfo.status=="t" &&(<p className="text-green-500">Active</p>)}
      {categoryInfo.status=="f" &&(<p className="text-red-500">Deactive</p>)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link to={`/edit-series?id=${categoryInfo.id}`} className="text-indigo-600 hover:text-indigo-900">
          
        <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
 </svg> </Link>


  
         <a href="javascript:void();"
        onClick={() => { 
          confirmAlert({
            title: 'Confirm to delete category',
            message: 'Are you sure to delete this category?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => handleConfirmDelete(categoryInfo.id)
              },
              {
                label: 'No',
                onClick: () => false
              }
            ]
          });
          }}
      className="text-red-600 hover:text-red-900">  <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
      </svg></a>
  
      </td>
    </tr>
  );
}

export default CategoryRow;
