import React, { useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import Loader from '../components/loader/loader';
import { ValidationMessage } from '../components/validation-message/validation-message';
import { ActionResponse } from '../contracts/response-models/action-response';
import { Product } from '../contracts/response-models/product';
import { thumbUrl } from '../utils/thumb-url';

interface imageInfo {
    id:number;
    fileName:string;
    fullUrl:string;
}
  
 
export const Upload = () => {  

  const [loading, setLoading] = useState(false);
  const [zipFile, setZipFile] = useState<any>();
  const [response, setResponse] = useState<ActionResponse<Product>>();
  const [isUploading,setIsUploading] = useState(false);
  const [imageUrlInfo,setImageUrlInfo]= useState<imageInfo[]>();
  const [product, setProduct] = useState<Product>({id:0});
    
  const uplaod_images = async(e:any) =>{
   
   const formData = new FormData()
   
      formData.append('files', zipFile)
   
   setLoading(true);
   uploadFiles(formData);
   setTimeout(function(){ 
     setResponse({
       status:"Success",
       message:"Zip File uploaded and Images are uploading..",
       data:[],
     })
     setLoading(false); }, 5000);
 } 

 const uploadFiles=async (formData:any)=>{
  //  var url = "http://127.0.0.1:8000/api/products/import";
    var url = "https://api.lihongart.com/api/upload-bulk-image";
    fetch(url, {  
      
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => {    
        console.log(data);
        var imageinfo = data.images;
        var filearray = [];
        var imageinfoarray = [];
        for(var i=0;i<imageinfo.length;i++)
        {
          filearray.push(imageinfo[i].fileName);
          if(imageUrlInfo!=undefined)
          {
            var itemid = imageUrlInfo?.length+i+1;
          }else
          {
            var itemid = i+1;
          }
         
          imageinfoarray.push({'id':itemid,'fileName':imageinfo[i].fileName,'fullUrl':thumbUrl+imageinfo[i].fileName});
        }     
  
        var emptyArr = new Array<string>()
        var arrPhotos = product.image == undefined ? emptyArr : product.image;
        arrPhotos = arrPhotos.concat(filearray);
  
        var emptyimageInfo = new Array<imageInfo>()
        var imagesInfo = imageUrlInfo == undefined ? emptyimageInfo : imageUrlInfo;
        imagesInfo = imagesInfo.concat(imageinfoarray);
        setImageUrlInfo(imagesInfo);
        setProduct({...product,image:arrPhotos});    
        setIsUploading(false); 
      })
      .catch(error => {
        console.error(error)
      }) 
 }

  return (
    
   
    <main className="flex-1 bg-white relative overflow-y-auto focus:outline-none" >
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
      <Breadcrumb mainTitle="Products" mainLink="/products" childTitle="Upload Images" childLink="" />        
  
        <h1 className="text-left text-2xl font-semibold text-gray-900">Upload Images</h1>
      </div>
      <div className="  mx-auto px-4 sm:px-6 md:px-8">
       
        <div className="py-4">
          <div className="  h-96">
         
          <form className="space-y-7 divide-y divide-gray-200">
              <div className="space-y-7 divide-y divide-gray-200 sm:space-y-5">
          

                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 ml-5 mr-5">
          
        
      
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ">Uplaod images by zip file</h3>
  
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex justify-left px-6 pt-5 pb-6  rounded-md">
                        <div className="space-y-4 text-left">
                     
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span> Upload zip file &nbsp; </span>
                              <input id="file-upload" name="file-upload" type="file"
                              onChange={(e)=> {
                                if(e.target.files)
                                {
                                  setZipFile(e.target.files[0]);
                                }
                               
                               }}
                              className="sr-only" />
                            </label>
 
                         
                            <p className="pl-1 hidden">or drag and drop</p>
                            <ValidationMessage isInvalid={(zipFile == undefined) || (zipFile != '') ? false : true} errorMessage="Please select csv file" />
                           
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                   
                  {loading ? (<Loader  />) : (
                    <>
                  {response?.status=="Error" && (<>
                          <p className="text-md text-left text-gray-500"><span className="text-red-600 hover:text-red-500">{response.message}</span></p>
                        </>)}
                  {response?.status=="Success" && (<>
                          <p className="text-md text-left  text-gray-500"><span className="text-green-600 hover:text-green-500">{response.message}</span></p>
                        </>)}
                        
                  <div className="pt-5 sm:border-t sm:border-gray-200 sm:pt-5">
                    <div className="flex justify-end ">
                      <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={uplaod_images}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
                      >
                        Upload file
                      </button>
                    </div>
                  </div>
                  </>
                      )}
                </div>  
              </div> 
            </form>
        
          </div>
        </div>
       
      </div>
    </div>
  </main>
  
  );
}

