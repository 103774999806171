import React from "react";
import { Switch } from "react-router-dom"; 


import { useMachine } from "@xstate/react";
import {EventData,State} from 'xstate';

import { loginMachine } from "../machines/login/machine";
import {Events as LoginEvents} from "../machines/login/events"
import {Context as LoginContext} from "../machines/login/state-context";

import PublicRoute from  "./public-route";
import PrivateRoute from  "./private-route";

import {LoginPage} from '../pages/LoginPage'
// import {RegisterPage} from '../pages/RegisterPage'
import {Dashboard} from '../pages/Dashboard'
import DashboardLayout from "../components/layouts/DashboardLayout";
import { ProductList } from "../pages/ProductList";
import AddProduct from "../pages/AddProduct";
import ProductPreview from "../pages/ProductPreview";
import OrderList from "../pages/OrderList";
import Reports from "../pages/Reports";
import ChangePassword from "../pages/ChangePassword";
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";
import Import from "../pages/ImportProduct";
import ImportLog from "../pages/ImportLog";
import CategoryList from "../pages/CategoryList";
import AddCategory from "../pages/AddCategory";
import OrderDetail from "../pages/OrderDetail";
import ProductPopUp from "../components/products/product-popover";
import { Upload } from "../pages/UplaodImages";
 
 
interface IProps { 
}

export default function Routes({}:IProps) {  

  const [stateLogin, sendLogin] = useMachine<LoginContext, LoginEvents>(loginMachine, {
    devTools: process.env.NODE_ENV === "development",
});
  return (
    <Switch>      
         
        <PublicRoute path="/login" exact component={LoginPage} state={stateLogin} send={sendLogin}   /> 
         
        <DashboardLayout>
        <PrivateRoute exact path="/" component={Dashboard}/>  
        <PrivateRoute exact path="/profile" component={Profile}/>
        <PrivateRoute exact path="/change-password" component={ChangePassword}/>
        <PrivateRoute exact path="/settings" component={Settings}/>
        <PrivateRoute exact path="/categories" component={CategoryList}/>
        <PrivateRoute exact path="/products" component={ProductList}/> 
        <PrivateRoute exact path="/import" component={Import}/> 
        <PrivateRoute exact path="/upload" component={Upload}/>
        <PrivateRoute exact path="/import-log" component={ImportLog}/>
        <PrivateRoute exact path="/series" component={CategoryList}/> 
        <PrivateRoute exact path="/add-series" component={AddCategory}/>
        <PrivateRoute exact path="/edit-series" component={AddCategory}/>  
        <PrivateRoute exact path="/add-product" component={AddProduct}/> 
        <PrivateRoute exact path="/edit-product" component={AddProduct}/> 
        <PrivateRoute exact path="/product-preview" component={ProductPreview}/>
        <PrivateRoute exact path="/product-popup/:id" component={ProductPopUp}/>
        <PrivateRoute exact path="/product-preview/:id" component={ProductPreview}/>
        <PrivateRoute exact path="/orders" component={OrderList}/> 
        <PrivateRoute exact path="/reports" component={Reports}/> 
        <PrivateRoute exact path="/order-detail" component={OrderDetail}/> 
        </DashboardLayout>
        
         

    </Switch>
  );
}
