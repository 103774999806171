import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from '../components/alert/alert';


import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import { Loader } from '../components/loader/loader';
import { CreateCategoryRequest } from '../contracts/request-models/create-category';
import { createCategoryService, editCategoryService, getCategoryService } from '../machines/admin/services';

interface imageInfo {
  id:number;
  fileName:string;
  fullUrl:string;
}

export const AddCategory = () => {  
  const [title,setTitle]=useState('');
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [error,setError]=useState('');
  const [isLoading,setIsLoading] = useState(false);
  const [loading,setLoading] = useState(false);
  
  let categoryId;
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  if(query.get("id")){
    let idParam = query.get("id");
    categoryId = idParam;
  }
  
  const [id, setId] = useState<any>(categoryId);

  useEffect(() => {    
    async function getCategory() {
      setLoading(true);
        if(id){
            const result = await getCategoryService(id);             
            setTitle(result.title); 
            
        }
        
      setLoading(false); 
    }
    getCategory();
},[id]);
  
  



 
  const saveCategory = async ()=>{
    setIsLoading(true);
    setError('');
    let request:CreateCategoryRequest= {
      title:title,
      status:'1'     
      };
   

    try {
      if(id!='' && id!=undefined)
      {
        const response = await editCategoryService(id,title);
        setAlertMessage("Series Updated Successfully.");
      }else
      {
        const result = await createCategoryService(request);
        setAlertMessage("Series Added Successfully.");
      }
      setDisplayAlert(true);
      setTimeout( () => {  setDisplayAlert(false)
          window.location.href="/series";
          } , 3000);
      setTimeout( () => {  setDisplayAlert(false)
        
      } , 5000);
    
    } catch (error:any) {
      
        setError(error.message);
        
    }
    setIsLoading(false);          
 }

 

return (
  <>
  
  <main className="flex-1 bg-white relative overflow-y-auto focus:outline-none" >
 
    <div className="py-6">
      <div className=" mx-auto px-4 sm:px-6 md:px-8">
        <Breadcrumb mainTitle="Series" mainLink="/series" childTitle={id!='' && id!=undefined   ?("Edit Series"):("Add Series")} childLink="" />        
      
      <h1 className="text-left text-2xl font-semibold text-gray-900">{id!='' && id!=undefined   ?("Edit Series"):("Add Series")}</h1> 
      </div>
      <div className=" mx-auto px-4 sm:px-6 md:px-8">
       
        <div className="py-4">
          
        {loading ? (<Loader  />) : (
            <div className="border-2 border-gray-200 rounded-lg ">
            
          <form className="space-y-8 divide-y divide-gray-200">
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      

      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 ml-5 mr-5">
      {displayAlert &&(
      <Alert type="success" message={alertMessage} displayAlert={displayAlert} setDisplayAlert={setDisplayAlert}  />
    )}
      
   
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ">Series Information</h3>

        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Title  
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                value={title}
                onChange={(e)=>{setTitle(e.target.value)}}
                className="border-2 p-2   rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-red-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              />
              
            
            </div>
          </div>
        </div>
       
      </div>
      
            

    </div>
    

    <div className="pt-5">
      <div className="flex justify-end">
        <button
          type="button"
          onClick={()=>{ window.location.href="/series"}}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={()=>{saveCategory();}}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
        >
          {isLoading && (
                            <svg className="animate-spin h-5 w-5 mr-3  rounded-full  border-t-2 border-b-2 border-white" viewBox="0 0 24 24"></svg>
                            )} 
          Save
        </button>
      </div>
      <div className="py-2">            
                    {error && (
                        <span>{error}</span>
                    )}  
                </div>
    </div>
  </form>
  </div>
  )}

        
        </div>
       
      </div>
    </div>
  </main>
</>
);

}

export default AddCategory;
