import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { getSeriesService, listProductsService } from '../machines/admin/services';
import { PagedResult } from '../contracts/response-models/paged-result';
import { Product } from '../contracts/response-models/product';
import Loader from '../components/loader/loader';
import ProductRow from '../components/products/product-row';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Alert from '../components/alert/alert';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import { FilterOption } from '../contracts/local/filter-option';

export const ProductList = () => {  
 
  const [loading, setLoading] = useState(false); 
  const [productResult, setProductResult] = useState<PagedResult<Product>>();
  const [products, setProducts] = React.useState<Product[]>([]);
  const [readyState, setReadyState] = useState(true);
  const [selectedCategories,setSelectedCategories] = useState<string[]>([]);
  const [paged, setPaged] = useState(1);
  const [perpagearecord, setPagePerRecord] = useState(10);
  const [sortedColumn, setSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('desc');
  const [refreshResult, setRefreshResult] = useState(true);
  const [hasMoreRecords, sethasMoreRecords] = useState(false);
  const [productChanged,setProductChanged] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [searchkey, setSearchkey] = useState('');
  const [alertMessage, setAlertMessage] = useState("");
  const [filters,setFilters] = useState<FilterOption[]>([]);

  useEffect(() => {
    async function loadSeries() {                  
        const result = await getSeriesService();  
        const catOptions = result.map((series)=>{
         return {value:series.id.toString(),label:series.title,checked:false};
        })  
        const catFilter = {
          id:'category',
          name:'Series',
          options:catOptions
        };    
        setFilters(filters => [...filters, catFilter]);

    
    }
    loadSeries();
    
  }, []);

  useEffect(() => {
    async function loadProoducts() {
      if (readyState == true) {
        setLoading(true);
        var cats = selectedCategories?.join(",");
        const productResult = await listProductsService(paged, perpagearecord, sortedColumn, sortDirection,searchkey,cats);
        setProductResult(productResult)
        if(refreshResult){
          setProducts(productResult.data);
        }
        else{
          setProducts(products.concat(productResult.data));
        } 
        setLoading(false);
        setReadyState(false);
      }
    }

    loadProoducts();

  }, [readyState, paged, perpagearecord, sortedColumn, sortDirection,selectedCategories,productChanged]);
  


  const handlePageChange = async (pageNumber: number) => {
    window.scrollTo(0, 0)
  //  SetCurrentPage(pageNumber);
    let currentPage = pageNumber;
    setPaged(currentPage);
    setReadyState(true);

  };

  return (
    
   
    <main className="flex-1 relative bg-white overflow-y-auto focus:outline-none" >
      <div className="py-6">
        <div className="  mx-auto px-4 sm:px-6 md:px-8">
          <Breadcrumb mainTitle="Products" mainLink="" childTitle="" childLink="" />        
        </div>
        <div className="flex justify-between items-center mb-6 mx-auto px-4 sm:px-6 md:px-8 "> 
          <div>
            <h1 className="text-left text-2xl font-semibold text-gray-900">Products</h1>
          </div>
          <Link to={'/add-product'} className="text-indigo-600 hover:text-indigo-900">
            <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg> Add Product
          </Link>
        </div>
        <div className="flex justify-between items-center mb-6 mx-auto px-4 sm:px-6 md:px-8">
          <div>
            
          </div>
          <div className="justify-self-end">
              <input type="text" placeholder={("Search")}  className=" border pl-2 mr-4 w-90 h-10 rounded-md 	border-gray-300  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500  hover:border-gray-500 "  onChange={(event)=>{
                 setLoading(true);
                 setReadyState(true);
                 setRefreshResult(true);
                 setPaged(1);
                 setSearchkey(event.target.value);
                 setProductChanged(!productChanged);
              }} />          
                
              <select
                onChange={(e)=>{
                 setLoading(true);
                  setReadyState(true);
                  setRefreshResult(true);
                  setPaged(1);
                  setSortedColumn(e.target.value);
                  setProductChanged(!productChanged);
                 }}
                className="mt-1 mr-4  pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md hover:border-gray-500"
                >
                <option value="point">Default</option>
                <option value="title">ItemNo</option>
                <option value="nw">NW</option>
                <option value="height">Height</option>
                <option value="cuft">Cuft</option>
                <option value="price">Price</option>
              </select>
              { filters.map((section)=>( 
                <select
                onChange={(e)=>{
                  selectedCategories.pop();
                  setLoading(true);
                  setReadyState(true);
                  setRefreshResult(true);
                  setPaged(1);
                  setProductChanged(!productChanged);
                  selectedCategories.push(e.target.value);
                  setProductChanged(!productChanged);
                  }}
                className="mt-1  pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md hover:border-gray-500"
                placeholder="Series"
                >
                <option value="">All Series</option>
                {section.options.map((option, optionIdx)=>{
                  return (<option value={option.value} id={`filter-${section.id}-${optionIdx}`}
                           >{option.label}</option>)
                })}
                 
                               
                </select>
              ))} 
            </div>
        </div>
         


        
        <div className=" mx-auto px-4 sm:px-6 md:px-8">
        {displayAlert &&(
      <Alert type="success" message={alertMessage} displayAlert={displayAlert} setDisplayAlert={setDisplayAlert}  />
    )}  
        {loading ? ( <div className="  mx-auto px-4 sm:px-6 md:px-8 text-center content-center	"> <Loader  /></div>) : (
          <div className="py-4">
           
            
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                        <th scope="col"  className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                           Image  
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            SKU 
                          </th>
                        
                          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Price
                          </th>
                          
                          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className=" px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                      {products?.map(function (productInfo, i) {
                        return (
                    <ProductRow productInfo={productInfo} setReadyState={setReadyState}  setDisplayAlert={setDisplayAlert} setAlertMessage={setAlertMessage} setLoading={setLoading} /> 
                        )
                      })}
                         
                      
                      </tbody>
                    </table>
                     
                      {productResult?.data!=undefined && (
                          <nav className="border-t border-gray-200 h-16 px-4 flex items-center justify-between sm:px-0">
                            <div className="pl-3">Displaying {((productResult.pageIndex-1)*perpagearecord)+1} -   &nbsp;
                              {productResult.hasNextPage ?(productResult.pageIndex*perpagearecord):(productResult.totalRecords)}  &nbsp; of &nbsp; {productResult.totalRecords} records
                            </div>
                            <div className="-mt-px w-0 flex-1 flex flex justify-end pr-4">
                              <Pagination
                                itemClass="border-t-2 border py-2 pr-1 inline-flex items-center text-sm font-medium text-gray-500  hover:border-gray-600"
                                linkClass="text-center h-5 w-9 text-gray-400 hover:text-gray-800"
                                activeClass="active border-2 border border-gray-600"
                                activeLinkClass="active"
                                activePage={productResult?.pageIndex}
                                itemsCountPerPage={10}
                                totalItemsCount={productResult?.totalRecords}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange}
                              />
                            </div>
                          </nav>
                      )}
                  </div>
                </div>
              </div>
            </div>

           
          </div>
          )}
        </div>
      </div>
    </main>
  
  );
}

export default ProductList;
