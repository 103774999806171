import { Machine,assign } from "xstate";
import {
    validateTokenService,
    fetchTokenService    
} from "./services";
import {Events} from "./events";
import {Context,StateSchema} from "./state-context";


import { guid } from "../../utils/guid";


export const loginMachine = Machine<Context,StateSchema,Events>(
    {
        id:"loginMachine",
        initial:"SPLASH",
        context:{},
        states:{
            SPLASH:{
                id:"spash",
                entry:[() => console.log("loginMachine.SPLASH")],
                on:{
                    SWITCH_ROUTE_LOGIN: {
                        target: "LOGIN",
                    }
                }
            },            
            LOGIN:{
                id:"Login",
                entry:[() => console.log("LOGIN")],
                initial:"VALIDATE_USER",
                states:{
                    VALIDATE_USER:{
                        id:"validate_user",
                        invoke:{
                            id:"validateToken",
                            src:validateTokenService,
                            onDone:{
                                target:"#loginMachine.DASHBOARD",
                                actions:assign((context,event)=>({
                                            ...context,
                                            user:{
                                                ...event.data,
                                            },
                                        })),
                            },
                            onError:{
                                target:"IDLE"
                            }
                            
                        }
                    },
                    IDLE:{
                        entry:[() => console.log("LOGIN.IDLE")],
                        on: {
                            SUBMIT_LOGIN_CREDENTIALS: {
                                target: "ACQUIRE_TOKEN",
                                actions: ["clearError"],
                            }
                        }
                    },
                    ACQUIRE_TOKEN:{
                        entry:["logEntry"],
                        invoke: {
                            id: "fetchToken",
                            src: fetchTokenService,
                            onDone: {
                                internal: false,
                                target: "#loginMachine.DASHBOARD",
                                actions: assign((context, event) => {
                                  // After the token is fetched and validated,
                                  // populate the user in the context
                                  return {
                                    ...context,
                                    user: {
                                      ...event.data,
                                    },
                                    error: undefined,
                                  };
                                }),
                            },
                            onError: {
                                target: "IDLE",
                                actions: assign((context, event) => {
                                  console.log("ACQUIRE_TOKEN > Error:", event);
                                  return {
                                    user: undefined,
                                    error: event.data.message,
                                    operation: guid(),
                                  };
                                }),
                            },
                        }
                    },
                    LOGGING_OUT:{

                    },
                }
            },
            DASHBOARD:{
                id:"dashboard",
                type: 'final',
                //context:{},
                entry:[()=>console.log("entry.DASHBOARD")]

            }
        },

    },
    {
        actions:{
            clearError: assign((context, event) => ({ error: undefined })),                                    
            logEntry:(context, event)=>{
                console.log("log.Entry");
                console.log(event);
            },
            logEntrySPLASH:(context, event)=>{
                console.log("log.logEntrySPLASH");
                console.log(event);
            },
            logEntryLOGIN:(context, event)=>{
                console.log("log.logEntryLOGIN");
                console.log(event);
            }
        },
        guards:{            
            userIsValid: (context, event) => {
                return Boolean(context.user?.isValid);
            },
            userIsNotValid: (context, event) => {
                return !Boolean(context.user?.isValid);
            },
        }
    }
)