import React from "react";
interface ValidationProps{
    isInvalid:boolean,
    hideDefault?:boolean,
    errorMessage:string
}
export const ValidationMessage = ({isInvalid,errorMessage,hideDefault}:ValidationProps) => {    
    if (isInvalid){
        if(hideDefault)
            return (<div></div>)

        return (    
            <div className="text-left text-red-600">{errorMessage}</div>
        ) 
    }
    else{
        return(<></>)
    }

}