import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { Order } from '../../contracts/response-models/order';
import { DeleteCategoryService } from '../../machines/admin/services'; 
interface OrderProps{
    orderInfo: Order; 
    setReadyState:any;
    setDisplayAlert:any;
    setAlertMessage:any;
    setLoading:any;
  }

function OrderRow({orderInfo,setReadyState,setDisplayAlert,setAlertMessage,setLoading}:OrderProps) { 
  let date= (orderInfo.created_at).toString().split('T')[0]
  date=date.split("-").reverse().join("-"); 


  
  return (
    <tr>
     
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm font-medium text-gray-900">
      <Link to={`/order-detail?id=${orderInfo.id}`}>{orderInfo.id}</Link> 
      </td> 
   
      <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {orderInfo.order_id}
      </td>
      <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {date}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link to={`/order-detail?id=${orderInfo.id}`} className="text-indigo-600 hover:text-indigo-900">
          <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
          </svg> 
        </Link>


  
         {/* <a href="javascript:void();"
        onClick={() => { 
          confirmAlert({
            title: 'Confirm to delete order',
            message: 'Are you sure to delete this order?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => handleConfirmDelete(orderInfo.id)
              },
              {
                label: 'No',
                onClick: () => false
              }
            ]
          });
          }}
      className="text-red-600 hover:text-red-900">  <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
      </svg></a> */}
  
      </td>
    </tr>
  );
}

export default OrderRow;
