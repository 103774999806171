import { client } from "../client";
import { Product } from "../../contracts/response-models/product";
import { PatchCategoryRequest } from "../../contracts/request-models/patch-category";
import { Category } from "../../contracts/response-models/category";

export function patchCategory(id: number, request: PatchCategoryRequest) {
  return client<Category>(`/category/${id}`, request, {
    method: "PATCH",
});
  
}