import { createCategory } from "../../api/categories/create";
import { deleteCategory } from "../../api/categories/delete";
import { getCategoryList } from "../../api/categories/get-categories";
import { getCategory } from "../../api/categories/getCategory";
import { listCategories } from "../../api/categories/list";
import { patchCategory } from "../../api/categories/patch-categories";
import { listImportLogs } from "../../api/import-log/list";
import { listOrders } from "../../api/orders/order-list";
import { getOrderInfo } from "../../api/orders/orders";
import { createProduct } from "../../api/products/create";
import { deleteProduct } from "../../api/products/delete";
import { getProductInfo } from "../../api/products/get";
import { importProduct } from "../../api/products/import";
import { listProducts } from "../../api/products/list";
import { patchProduct } from "../../api/products/patch";
import { listSeries } from "../../api/series/list";
import { CreateCategoryRequest } from "../../contracts/request-models/create-category";
import { CreateProductRequest } from "../../contracts/request-models/create-product"; 
import { ImportProductRequest } from "../../contracts/request-models/import-product-csv";
import { PatchCategoryRequest } from "../../contracts/request-models/patch-category";
import { PatchProductRequest } from "../../contracts/request-models/patch-product";
import { Product } from "../../contracts/response-models/product";

export const createProductService = async (product: Product) => {
  let request: CreateProductRequest = {
    category_id:product.category_id,
    title: product.title, 
    meta_title:product.meta_title,
    meta_tag_keywords:product.meta_tag_keywords,
    meta_tag_description:product.meta_tag_description,
    sku_id:product.sku_id,
    size:product.size,
    price:product.price,
    description: product.description,
    description_english: product.description_english,
    nw: product.nw,
    gw: product.gw,
    height: product.height,
    cuft: product.cuft,
    point: product.point,
    image:product.image,
    status:product.status,
  };
  const createResponse = await createProduct(request);
  return createResponse;
};

export const listProductsService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string,searchKey:string,categories?:string) => {
  const products = await listProducts(paged, perpagearecord, sortedColumn, sortDirection,searchKey,categories);
  return products;
};

export const getSeriesService = async () => {
  const seriesList = await listSeries();
  return seriesList;
};

export const getProductInfoService = async (id: number) => {
  const productinfo = await getProductInfo(id);
  return productinfo;
};

export const editProductService = async (product: Product,pid:number) => {
  let request: PatchProductRequest = {
    id:product.id,
    category_id:product.category_id,
    title: product.title, 
    meta_title:product.meta_title,
    meta_tag_keywords:product.meta_tag_keywords,
    meta_tag_description:product.meta_tag_description,
    sku_id:product.sku_id,
    size:product.size,
    price:product.price,
    description: product.description,
    description_english: product.description_english,
    nw: product.nw,
    gw: product.gw,
    height: product.height,
    cuft: product.cuft,
    point: product.point,
    image:product.image,
    status:product.status,
  };
  const createResponse = await patchProduct(pid,request);
  return createResponse;
};

export const DeleteProductService = async (pid: any) => {
  const post = await deleteProduct(pid);
  return post;
};

export const importProductService = async (csv: File) => {
  let request: ImportProductRequest = {
    csvfile:csv,
    
  };
  const createResponse = await importProduct(request);
  return createResponse;
};

export const listLogService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const importlog = await listImportLogs(paged, perpagearecord, sortedColumn, sortDirection);
  return importlog;
};

export const getCategoryListService = async () => {
  const catInfo = await getCategoryList();
  return catInfo;
};

export const getCategoryService = async (id:number) => {
  const catInfo = await getCategory(id);
  return catInfo;
};

export const listCategoryService = async (paged: number, perpagearecord: number, sortedColumn: string, sortDirection: string) => {
  const categories = await listCategories(paged, perpagearecord, sortedColumn, sortDirection);
  return categories;
};

export const listOrderService = async (paged: number) => {
  const orders = await listOrders(paged);
  return orders;
};


export const getOrderService = async (order_id:number) => {
  const getOrder = await getOrderInfo(order_id);
  return getOrder;
};

export const createCategoryService = async (createCategoryRequest:CreateCategoryRequest) => {
  const createCat = await createCategory(createCategoryRequest);
  return createCat;
};

export const editCategoryService = async (id:number,title: string) => {
  let request: PatchCategoryRequest = {
    id:id,
    title:title,
    status:'1'
  };
  const createResponse = await patchCategory(id,request);
  return createResponse;
};


export const DeleteCategoryService = async (pid: any) => {
  const post = await deleteCategory(pid);
  return post;
};


