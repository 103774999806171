import { Fragment, useEffect, useState } from 'react';
import {  XIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import { Product } from '../../contracts/response-models/product';
import { useLocation } from 'react-router-dom';
import { getProductInfoService } from '../../machines/admin/services';
import Loader from '../loader/loader';

interface ProductPopUpParam{
    open:boolean;
    setOpen:any;
    product:any
  }

export default function ProductPopUp({open,setOpen,product}:ProductPopUpParam) {
  const [loading,setLoading] = useState(false);
  
  
  return (
    <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                   
                  </div> 
                  {loading ?( <div className="  mx-auto px-4 sm:px-6 md:px-8 text-center content-center	"> <Loader  /></div>) :(<img   
                    src={"https://api.lihongart.com/storage/uploads/"+product?.image?.[0]}                 
                        className="w-96 h-96 object-center object-contain sm:w-96 sm:h-96"
                  /> )}                              
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
     )
}

