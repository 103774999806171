import {
    getTokenFromLocalStorage
} from "../api/client";

import {hasTokenExpired} from "./has-token-expired"

export const hasValidToken = () => {
    
  
    const storageToken = getTokenFromLocalStorage();  
    if (storageToken) {
       // if(hasTokenExpired(storageToken.token))
       if(storageToken.token && storageToken.isAdmin=='yes')
            return true;
        else
            return false;
    }
    return false;
};