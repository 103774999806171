import React, { PureComponent } from "react";
 
 
//import Footer from "./footer/Footer"

import Header from "./header/Header"
import Menu from "./menu/Menu"

 
class DashboardLayout extends PureComponent<any> {

  
    render() {
    return (
        <div className="App">      
<div className="h-screen flex overflow-hidden bg-gray-100">
<Menu/>     

 
  <div className="flex flex-col w-0 flex-1 overflow-hidden">
  <Header/>
   
  <>{this.props.children}</>
  </div>
</div>


  </div>
        
       
    )
  }
}
  
export default DashboardLayout