import React, { useState,useRef } from 'react'; 
import { history } from "../history"
import { ValidationMessage } from '../components/validation-message/validation-message';

import { EventData, State } from "xstate";
import { EventTypes, Events } from "../machines/login/events";
import { Context } from "../machines/login/state-context";
import logo from "../assets/img/logo.png"
import Loader from '../components/loader/loader';
interface LoginPageProps {
  state: State<Context, Events, any, any>;    
  send: (event: EventTypes, payload?: EventData | undefined) => {};
}

export const LoginPage= ({state,send}:LoginPageProps) => {  
  
  const [baseURI,setBaseURI] = useState("https://api.lihongart.com/api")
  const readyForLogin = state.matches("SPLASH");
  
  React.useEffect(() => {              
    if(readyForLogin){
        send("SWITCH_ROUTE_LOGIN");
    }
    
  }, [state]);

  const loading =  state.matches({ LOGIN: "VALIDATE_USER" }) || state.matches({ LOGIN: "ACQUIRE_TOKEN" }) ||
  state.matches({ LOGIN: "ACQUIRE_API_URL" }) ||      
  state.matches("SPLASH");

  const [usernameField, setUserNameField] = useState<string>();
  const [passwordField, setPasswordField] = useState<string>();
  
  const inputUsernameRef = useRef<HTMLInputElement>(null);

  const loginLand = true;//state.matches({ LOGIN: "IDLE" }) || state.matches({ LOGIN: "ACQUIRE_TOKEN" });

  React.useEffect(() => {      
    if (inputUsernameRef.current !== null) {
      inputUsernameRef.current!.focus();
    }
  }, [loginLand]);

  function handleLoginClick(e:any)
  {    
      e.preventDefault();
      history.push('/');
  }

  if(state.matches("DASHBOARD"))
  {
    window.location.href="/";
  }

 
  return (
    <div className="App">   <meta name="csrf-token" content="{{ csrf_token() }}" />
  <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
      
    </div>
  
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-6" action="" method="get" autoComplete="off">
          <div>
            <label  className="block text-sm font-medium text-gray-700 text-left">
              Email address
            </label>
            <div className="mt-1">
              <input 
             
                 id="email" 
                 name="email" 
                 type="email"
                 autoComplete="off"
                 value={usernameField}
                 onChange={({ target }) => setUserNameField(target.value)}

                 onKeyPress={(event) => {
                  if (
                    event.key === "Enter" &&
                    usernameField &&
                    passwordField
                  ) {
                    send("SUBMIT_LOGIN_CREDENTIALS", {
                      uri: baseURI,
                      username: usernameField,
                      password: passwordField,
                    });
                  }
                }} 
                 required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                 />
                 
                   <ValidationMessage isInvalid={(usernameField == undefined) || (usernameField != '') ? false : true} errorMessage="Please type login email" />
                 
            </div>
          </div>
  
          <div>
            <label  className="block text-sm font-medium text-gray-700 text-left">
              Password
            </label>
            <div className="mt-1">
              <input 
              autoComplete="off"
              id="password" 
              name="password" 
              type="password" 
              value={passwordField}
                onChange={({ target }) => setPasswordField(target.value)}        
                        onKeyPress={(event) => {
                          if (
                            event.key === "Enter" &&
                            usernameField &&
                            passwordField
                          ) {
                            send("SUBMIT_LOGIN_CREDENTIALS", {
                              uri: baseURI,
                              username: usernameField,
                              password: passwordField,
                            });
                          }
                        }}    

              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
              />
               
                   <ValidationMessage isInvalid={(passwordField == undefined) || (passwordField != '') ? false : true} errorMessage="Please type password" />
                
            </div>
          </div>
  
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label   className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>
  
            <div className="text-sm">
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </a>
            </div>
          </div>
  
          <div>
            {loading ? (<Loader type="login" />):(

            
            <button type="button"
             disabled={!usernameField && !passwordField}
             onClick={(e) => {
              e.preventDefault();
              
              if (usernameField && passwordField) {
                send("SUBMIT_LOGIN_CREDENTIALS", {
                  uri: baseURI,
                  username: usernameField,
                  password: passwordField,
                });
              }
            }}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Sign in
            </button>)}
            
                   
                           
            {state.context.error && (   
                    <div className="text-red-600 text-left pt-2">                       
                    {state.context.error} 
                    </div>
                    )}
                    
       
          </div>
        </form>
  
         
      </div>
    </div>
  </div>
  

  </div>
  );
}

export default LoginPage;
