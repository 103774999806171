import React from 'react';
 
interface TypeProps {
    type?:string,
    message?:string,
    displayAlert?:any,
    setDisplayAlert?:any,
   
  }
  
export const Alert = ({ type,message,displayAlert,setDisplayAlert }: TypeProps) => {
 
  var color;
  if(type=='error')
  {
    color="red";
  }
  if(type=='success')
  {
    color="green";
  }
  
    return (
      <div
      className={
        "text-white px-6 py-4 border-0 rounded relative mb-4  ml-2 pl-1 text-left bg-" +
        color +
        "-500"
      }
    >
      <span className="text-xl inline-block mr-5 align-middle">
        <i className="fas fa-bell" />
      </span>
      <span className="text-left inline-block align-middle mr-8">
         {message}
      </span>
      <button
            className=" absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={() => setDisplayAlert(false)}
          >
            <span>×</span>
          </button>
    </div>
        
    )
}

export default Alert;