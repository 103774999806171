import React from 'react';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
 
export const ChangePassword = () => {  
  return (
     
  <main className="flex-1 relative overflow-y-auto focus:outline-none bg-white" >
  <div className="py-6">
    <div className="  mx-auto px-4 sm:px-6 md:px-8">
    <Breadcrumb mainTitle="Change Password" mainLink="" childTitle="" childLink="" />        
  
      <h1 className="text-left text-2xl font-semibold text-gray-900">Change Password</h1>
    </div>
    <div className="  mx-auto px-4 sm:px-6 md:px-8">
    
      <div className="py-4">
        <div className="  h-96">
          <div className="h-98 bg-white flex  justify-center py-8   sm:px-6 lg:px-">
            <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow  sm:rounded-lg sm:px-10">
                <form className="space-y-6" action="#" method="POST">
                  <div>
                    <label htmlFor="current_Password" className="block text-sm text-left font-medium text-gray-700">
                    Current Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="current_Password"
                        name="current_Password"
                        type="Password"
                        
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
​
                  <div>
                    <label htmlFor="new_password" className="block text-sm text-left font-medium text-gray-700">
                      New Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="new_password"
                        name="new_password"
                        type="Password"
                        
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
​
                  <div>
                    <label htmlFor="confirm_password" className="block text-sm text-left font-medium text-gray-700">
                      Confirm password
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirm_password"
                        name="confirm_password"
                        type="Password"
                        
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
​
                  
​
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Change password
                    </button>
                  </div>
                </form>
​
              
              </div>
            </div>
</div>
​
​
        </div>
      </div>
    
    </div>
  </div>
</main>
  );
}

export default ChangePassword;
