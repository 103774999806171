import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { listLogService, listProductsService } from '../machines/admin/services';
import { PagedResult } from '../contracts/response-models/paged-result';
import { Product } from '../contracts/response-models/product';
import Loader from '../components/loader/loader';
import ProductRow from '../components/products/product-row';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Alert from '../components/alert/alert';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import { importLog } from '../contracts/response-models/import-log';
import ImportLogRow from '../components/import-log/import-log-row';

export const ImportLog = () => {  
 
  const [loading, setLoading] = useState(false); 
  const [logResult, setLogResult] = useState<PagedResult<importLog>>();
  const [log, setLog] = React.useState<importLog[]>([]);
  const [readyState, setReadyState] = useState(true);
  const [paged, setPaged] = useState(1);
  const [perpagearecord, setPagePerRecord] = useState(10);
  const [sortedColumn, setSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('desc');

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    async function loadLogData() {
      if (readyState == true) {
        setLoading(true);
        const logResult = await listLogService(paged, perpagearecord, sortedColumn, sortDirection);
        setLogResult(logResult)
        setLog(logResult.data); 
        setLoading(false);
        setReadyState(false);
         
      }
    }

    loadLogData();

  }, [readyState, paged, perpagearecord, sortedColumn, sortDirection]);
  


  const handlePageChange = async (pageNumber: number) => {
    window.scrollTo(0, 0)
  //  SetCurrentPage(pageNumber);
    let currentPage = pageNumber;
    setPaged(currentPage);
    setReadyState(true);

};

  return (
    
   
    <main className="flex-1 relative bg-white overflow-y-auto focus:outline-none" >
      <div className="py-6">
        <div className="  mx-auto px-4 sm:px-6 md:px-8">
        <Breadcrumb mainTitle="Products" mainLink="/products" childTitle="Import Logs" childLink="" />        
  
          <h1 className="text-left text-2xl font-semibold text-gray-900">Import Logs</h1>
        </div>
        <div className="flex justify-end mr-10"> 
        </div>
        <div className=" mx-auto px-4 sm:px-6 md:px-8">
        {displayAlert &&(
      <Alert type="success" message={alertMessage} displayAlert={displayAlert} setDisplayAlert={setDisplayAlert}  />
    )}  
        {loading ? ( <div className="  mx-auto px-4 sm:px-6 md:px-8 text-center content-center	"> <Loader  /></div>) : (
          <div className="py-4">
           
            
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                        <th scope="col" className=" px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID  
                          </th>
                          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Start 
                          </th>
                        
                          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Completed
                          </th>
                          
                          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                      {logResult?.data?.map(function (logInfo, i) {
                        return (
                            <ImportLogRow logInfo={logInfo} setReadyState={setReadyState}  setDisplayAlert={setDisplayAlert} setAlertMessage={setAlertMessage} setLoading={setLoading} /> 
                     
                       )
                      })}
                         
                      
                      </tbody>
                    </table>
                     
                                  {logResult?.data!=undefined && (
                                                   <nav className="border-t border-gray-200 h-16 px-4 flex items-center justify-between sm:px-0">
                                                <div className="pl-3">Displaying {((logResult.pageIndex-1)*perpagearecord)+1} -   &nbsp;
                                                {logResult.hasNextPage ?(logResult.pageIndex*perpagearecord):(logResult.totalRecords)}  &nbsp; of &nbsp; {logResult.totalRecords} records</div>
                                               
                                               
                                                   <div className="-mt-px w-0 flex-1 flex flex justify-end pr-4">
                                                       <Pagination
                                                             itemClass="border-t-2 border py-2 pr-1 inline-flex items-center text-sm font-medium text-gray-500  hover:border-gray-600"
                                                             linkClass="text-center h-5 w-9 text-gray-400 hover:text-gray-800"
                                                             activeClass="active border-2 border border-gray-600"
                                                             activeLinkClass="active"
                                                             activePage={logResult?.pageIndex}
                                                             itemsCountPerPage={10}
                                                             totalItemsCount={logResult?.totalRecords}
                                                             pageRangeDisplayed={5}
                                                             onChange={handlePageChange}
                                                         />
                                                   </div>
                                                 </nav>
                                                            )}
                                                    
                  </div>
                </div>
              </div>
            </div>

           
          </div>
          )}
        </div>
      </div>
    </main>
  
  );
}

export default ImportLog;
