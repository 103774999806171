import React from 'react';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
 
export const Settings = () => {  
  return (
    
   
    <main className="flex-1 relative overflow-y-auto focus:outline-none" >
    <div className="py-6">
      <div className="  mx-auto px-4 sm:px-6 md:px-8">
      <Breadcrumb mainTitle="Settings" mainLink="" childTitle="" childLink="" />        
  
        <h1 className="text-left text-2xl font-semibold text-gray-900">Settings</h1>
      </div>
      <div className="  mx-auto px-4 sm:px-6 md:px-8">
       
        <div className="py-4">
          <div className="  h-96">


          </div>
        </div>
       
      </div>
    </div>
  </main>
  
  );
}

export default Settings;
