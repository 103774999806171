import {client} from "../client";
 import {Product} from "../../contracts/response-models/product"
import {PagedResult} from "../../contracts/response-models/paged-result"

 
function listProducts(paged:number,perpagearecord:number,sortedColumn:string,sortDirection:string,searchKey:string,categories?:string){
    var catParam = categories!=undefined ? categories : '';
    return client<PagedResult<Product>>(`/products/listing/${perpagearecord}/${sortedColumn}/${sortDirection}?page=${paged}&searchkey=${searchKey}&categoryid=${catParam}`);   
}

export { listProducts }