

import React from 'react';
import logo from './logo.svg';
import { history } from "./utils/history";
import { useRouterMachine } from 'use-router-machine'
import { matchesState } from 'xstate'
 
import './App.css';
import './assets/main.css';
import { Router } from 'react-router-dom';
import Routes from './routes';

function Lihongart() {
  return (
    <>
    <Router history={history}>
      <Routes />
    </Router> 
    </> 
     
  );
}

export default Lihongart;
