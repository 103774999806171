import React, { useState } from 'react';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import Loader from '../components/loader/loader';
import { ValidationMessage } from '../components/validation-message/validation-message';
import { ActionResponse } from '../contracts/response-models/action-response';
import { Product } from '../contracts/response-models/product';
import { importProductService } from '../machines/admin/services';
import { fullUrl } from '../utils/full-image-url';
 
export const Import = () => {  

  const [loading, setLoading] = useState(false);
  const [csvfile, setCsvfile] = useState<any>();
  const [response, setResponse] = useState<ActionResponse<Product>>();

  const import_products = async(e:any) =>{
     e.preventDefault();
    const formData = new FormData()
    formData.append('csvfile', csvfile)
    setLoading(true);
    uploadFiles(formData);
    setTimeout(function(){ 
      setResponse({
        status:"Success",
        message:"Excel File uploaded and data importing started..",
        data:[],
      })
      setLoading(false); }, 5000);
  } 

  const uploadFiles=async (formData:any)=>{
   //  var url = "http://127.0.0.1:8000/api/products/import";
     var url = "https://api.lihongart.com/api/products/importbulk";
    fetch(url, {  
      
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {    
      console.log(data);
      setResponse(data); 
      setLoading(false);
    })
    .catch(error => {
      console.error(error)
      setLoading(false);
    }) 
  }

  return (
    
   
    <main className="flex-1 bg-white relative overflow-y-auto focus:outline-none" >
    <div className="py-6">
      <div className="mx-auto px-4 sm:px-6 md:px-8">
      <Breadcrumb mainTitle="Products" mainLink="/products" childTitle="Import Products" childLink="" />        
  
        <h1 className="text-left text-2xl font-semibold text-gray-900">Import Products</h1>
      </div>
      <div className="  mx-auto px-4 sm:px-6 md:px-8">
       
        <div className="py-4">
          <div className="  h-96">
         
          <form className="space-y-7 divide-y divide-gray-200">
              <div className="space-y-7 divide-y divide-gray-200 sm:space-y-5">
          

                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 ml-5 mr-5">
          
        
      
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ">Import Products by Excel file</h3>
  
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex justify-left px-6 pt-5 pb-6  rounded-md">
                        <div className="space-y-4 text-left">
                     
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span> Upload a file &nbsp; </span>
                              <input id="file-upload" name="csvfile" type="file"
                              onChange={(e)=> {
                                if(e.target.files)
                                {
                                  setCsvfile(e.target.files[0]);
                                }
                               
                               }}
                              className="sr-only" />
                            </label>
 
                         
                            <p className="pl-1 hidden">or drag and drop</p>
                            <ValidationMessage isInvalid={(csvfile == undefined) || (csvfile != '') ? false : true} errorMessage="Please select excel file" />
                           
                          </div>
                         
                          <div className="mt-12	-space-y-40">
                          <p className="text-sm  text-gray-500">Download <a href={fullUrl+"sample_product_format.xlsx"}><span className="text-indigo-600 hover:text-indigo-500">Sample Excel File</span></a>  Template</p>
                     
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? (<Loader  />) : (
                    <>
                  {response?.status=="Error" && (<>
                          <p className="text-md text-left text-gray-500"><span className="text-red-600 hover:text-red-500">{response.message}</span></p>
                        </>)}
                  {response?.status=="Success" && (<>
                          <p className="text-md text-left  text-gray-500"><span className="text-green-600 hover:text-green-500">{response.message}</span></p>
                        </>)}
                  <div className="pt-5 sm:border-t sm:border-gray-200 sm:pt-5">
                    <div className="flex justify-end ">
                      <button
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={async (e)=> { 

                          if(csvfile!=undefined && csvfile !="")
                          {
                            import_products(e);
                            /*
                            setLoading(true);
                            console.log("CSV File "+csvfile); 
                            const response = await importProductService(csvfile);
                            setAlertMessage("Product Added Successfully.");
                            setLoading(false)
                            */
                          }else
                          { 
                            setCsvfile("");
                          }
                        } }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
                      >
                        Upload file
                      </button>
                    </div>
                  </div>
                  </>
                      )}
                </div>  
              </div> 
            </form>
        
          </div>
        </div>
       
      </div>
    </div>
  </main>
  
  );
}

export default Import;
function setAlertMessage(arg0: string) {
  throw new Error('Function not implemented.');
}

