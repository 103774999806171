import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { Product } from '../../contracts/response-models/product';
import { DeleteProductService } from '../../machines/admin/services';
import ProductPopUp from './product-popover';
 
interface ProductProps{
    productInfo: Product; 
    setReadyState:any;
    setDisplayAlert:any;
    setAlertMessage:any;
    setLoading:any;
  }

function ProductRow({productInfo,setReadyState,setDisplayAlert,setAlertMessage,setLoading}:ProductProps) { 
 
  const handleConfirmDelete = async (pid: any) => {
    setLoading(true);
    if (pid > 0) { 
      const rsp = await DeleteProductService(pid); 
      setDisplayAlert(true);
      setAlertMessage("Product Deleted Successfully.")
      setTimeout( () => {  setDisplayAlert(false) 
      } , 2000);
      
      setReadyState(true);
      setLoading(false);
    }
  }
  const [open,setOpen] = useState(false); 
  
  if(open)
      return (
        <ProductPopUp open={open} setOpen={setOpen} product={productInfo}/>
      )
  
  return (
    <tr>
      <td className="text-left px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        
          <img   
          src={"https://api.lihongart.com/storage/uploads/thumbnail/"+productInfo?.image?.[0]}                 
            className="w-16 h-16 object-center object-contain sm:w-16 sm:h-16"
            onClick={()=>{setOpen(!open)}}
          />
        
      </td>
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">
        <Link to={`/product-preview?id=${productInfo.id}`} >{productInfo.sku_id}</Link> 
      </td> 
    
      <td className="px-6 py-4  whitespace-nowrap  text-sm text-gray-500"> 
        {productInfo.price!=undefined && productInfo.price!=null  &&(<>CNY{productInfo.price}</>)}
      </td>
      
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {productInfo.status=="1" &&(<p className="text-green-500">Active</p>)}
        {productInfo.status=="2" &&(<p className="text-red-500">Deactive</p>)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link to={`/edit-product?id=${productInfo.id}`} className="text-indigo-600 hover:text-indigo-900">
          <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg> 
        </Link>
        <a href="javascript:void();"
          onClick={() => { 
            confirmAlert({
              title: 'Confirm to delete product',
              message: 'Are you sure to delete this product?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => handleConfirmDelete(productInfo.id)
                },
                {
                  label: 'No',
                  onClick: () => false
                }
              ]
            });
          }}
          className="text-red-600 hover:text-red-900">  
            <svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
        </a>
      </td>
    </tr>
  );
}

export default ProductRow;
