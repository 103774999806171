import React, { useEffect, useState } from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { useParams } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import Loader from '../components/loader/loader';
import { Product } from '../contracts/response-models/product';
import { getProductInfoService } from '../machines/admin/services';
import { baseUrl } from '../utils/base-url';
import { fullUrl } from '../utils/full-image-url';
import { thumbUrl } from '../utils/thumb-url';
 
interface imageInfo {
  id:number;
  fileName:string;
  fullUrl:string;
}

export const ProductPreview = () => {  
  const [loading,setLoading] = useState(false);
  
  const [product, setProduct] = useState<Product>();
  const [imageUrlInfo,setImageUrlInfo]= useState<imageInfo[]>();
  let id;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  if(query.get("id")){
    let idParam = query.get("id");
    id = idParam;
  }

//  let { id } = useParams();
const [productid, setproductid] = useState<any>(id);

  useEffect(() => {    
    async function getProduct() {
      setLoading(true);
        if(productid){
            const productinfo = await getProductInfoService(productid); 
            console.log(productinfo); 
            setProduct(productinfo);  
            
        }
      setLoading(false); 
    }
    getProduct();
},[id]);

useEffect(() => {    
  async function setimageUrl() {
    var imgArr = new Array<imageInfo>()
    product?.image?.map((imageInfo, index) => {
      var itemid = index+1;
      imgArr?.push({
        id:itemid,
        fileName: imageInfo.toString(),
        fullUrl: baseUrl+imageInfo.toString(), 
    });
    })
    if(imageUrlInfo?.length==0 || imageUrlInfo?.length == undefined)
    {
      setImageUrlInfo(imgArr);
    }
   

  }
  setimageUrl();
},[product?.image]);
  return ( 
    <main className="flex-1 relative overflow-y-auto focus:outline-none bg-white" >
      <div className="py-6">
        
        <div className="mx-auto px-4 sm:px-6 md:px-8">
      
        {loading ? ( <div className="  mx-auto px-4 sm:px-6 md:px-8 text-center content-center	"> <Loader  /></div>) : (
         <>
           <Breadcrumb mainTitle="Products" mainLink="/products" childTitle={product?.title!='' && product?.title!=undefined ?(product?.title):("")} childLink="" />        
  
         <h1 className="text-left text-2xl mt-4 font-semibold text-gray-900">Products - {product?.sku_id}</h1>
         
         <div className="flex justify-end mr-1"> 
        <Link to={`/edit-product?id=${product?.id}`} className="text-indigo-600 hover:text-indigo-900">
   
<svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg> Edit Product</Link></div>
          <div className="py-4">
            
            <div className="border-2 border-solid border-gray-200 rounded-lg h-125">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                 
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0 text-left">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Product name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.title} </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">SKU</dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.sku_id} </dd>
                        </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Carton spec</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.size} </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">N.W.</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.nw} </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">G.W.</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.gw} </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Height</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.height} </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Cuft</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{product?.cuft} </dd>

                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500"> Price</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"> {product?.price!=undefined && product.price!=null  &&(<>CNY{product.price}</>)} </dd>
                      </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">Description</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {product?.description} 
                      </dd>
                    </div>

                   
                    {product?.image!=undefined && product?.image.length>0 && (
                      <>
<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
<dt className="text-sm font-medium text-gray-500">Product Images</dt>
<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
 
</dd>
</div>
   <div className="mx-auto max-w-md	 py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
   <div className="space-y-12">
     
     <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">

     {imageUrlInfo?.map(function (productInfo, i) {
                        return (
  
      
      <li className="m-2" >
                        <div className="space-y-4">
                        
                          <div className="aspect-w-1 aspect-h-1 shadow-sm "> 
                           <a href={fullUrl+productInfo.fileName.toString()} target="_blank"> <img className="object-cover shadow-lg rounded-lg h-40 w-52" width="150" height="150" src={thumbUrl+productInfo.fileName.toString()}/></a>
                          </div>
                        </div>
                      </li>
      
      )
    })}
</ul>
                  </div>
                </div>  

</>
)}   
                

                  </dl>
                </div>
              </div>
            </div>
          </div>
          </>
        )}  
        </div>
      </div>
    </main>
  
  );
}

export default ProductPreview;
