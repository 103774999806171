import { Fragment, useEffect, useState } from 'react'
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb'
import { useLocation } from 'react-router-dom'
import { CartItem } from '../contracts/response-models/cart'
import { getOrderService } from '../machines/admin/services'
import { Order } from '../contracts/response-models/order'



export default function OrderDetail() {

    const [orderItems,setOrderItems]=useState<CartItem[]>([]);
    const [orderDetail,setOrderDetail]=useState<Order>();
    const [date,setDate]=useState('');
    const [title,setTitle]=useState('');
    

    const priceType=window.localStorage.getItem('__currency_type__');
    const [memo,setMemo]=useState('');
    const [total,setTotal]=useState<number>(0);
    const [subTotal,setSubTotal]=useState<number>(0);  
    let orderId;
  
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    if(query.get("id")){
      let idParam = query.get("id");
      orderId = idParam;
    }
     
    const [id, setId] = useState<any>(orderId);

    useEffect(() => {    
        async function getOrder() {
          
            if(id){
                const orderDetail = await getOrderService(id);             
                setOrderItems(orderDetail.order_items);
                setMemo(orderDetail.memo);
                setTitle((orderDetail.order_id).toString());
                setOrderDetail(orderDetail);
                let Date= (orderDetail.created_at).toString().split('T')[0]
                Date=Date.split("-").reverse().join("-");
                setDate(Date);
                var calculatedSubTotal
                if(priceType=='Yuan')
                { 
                    calculatedSubTotal =  orderDetail.order_total;
                    calculatedSubTotal= Number.parseInt(calculatedSubTotal.toString());
                }
                else{
                    calculatedSubTotal = orderDetail.order_total_usd;    
                   
                }
                setSubTotal(calculatedSubTotal);
                //setTotal(calculatedSubTotal+(shippingEstimate+taxEstimate));
            }
           
          
        }
        
        getOrder();
        
    },[id]);

    

  return (
    <div className="bg-white overflow-y-auto">
      <div className="py-6 ">
        <div className=" max-w-7xl  sm:px-2 lg:px-8">
          <div className="max-w-2xl  px-4 lg:max-w-4xl lg:px-0 text-left">
          <Breadcrumb mainTitle="Orders" mainLink="/orders" childTitle={title} childLink="" />        
            
            
          </div>
        </div>

        <div className="mt-8">
         
          <div className="max-w-7xl  sm:px-2 lg:px-8  ">
            <div className="max-w-2xl  space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {orderDetail && (
               
                  <div className="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border">
                    <h3 className="sr-only">
                      Order placed on <time dateTime={orderDetail.created_at}>{orderDetail.created_at}</time>
                    </h3>
  
                    <div className="flex  items-center p-4 border-b border-gray-200 sm:p-6 sm:grid sm:grid-cols-4   sm:gap-x-6">
                      <dl className="flex-1 grid grid-cols-4 gap-x-6 text-sm sm:col-span-2 sm:grid-cols-3 lg:col-span-2">
                        <div>
                          <dt className="font-medium text-gray-900">Order number</dt>
                          <dd className="mt-1 text-gray-500">{orderDetail.order_id}</dd>
                        </div>
                        <div className="hidden sm:block">
                          <dt className="font-medium text-gray-900">Date placed</dt>
                          <dd className="mt-1 text-gray-500">
                            <time >{date}</time>
                          </dd>
                        </div>
                        <div>
                          <dt className="font-medium text-gray-900">Total amount</dt>
                          <dd className="mt-1 font-medium text-gray-500">¥ {orderDetail.order_total}</dd>
                        </div>
                      </dl>
                    </div>
  
                    {/* Products */}
                    <h4 className="sr-only">Items</h4>
                    <ul role="list" className="divide-y divide-gray-200">
                      {orderItems && orderItems.map((product) => (
                        <li className="p-4 sm:p-6">
                          <div className="flex items-center sm:items-start">
                            <div className="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                              <img
                              //   src={product.imageSrc}
                                src={"https://api.lihongart.com/storage/uploads/productphotos/"+product.sku_id+".png"}
                              //   alt={product.imageAlt}
                                className="w-full h-full object-center object-cover"
                              />
                            </div>
                            <div className="flex-1 ml-6 text-sm">
                              <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                                <h5>{product.title}</h5>
                                
                                {/* <p className="mt-2 sm:mt-0">{product.price}</p> */}
                              </div>
                              <div className="font-medium text-gray-900 pt-3 sm:flex sm:justify-between">
                                <h5>Quantity: {product.quantity}</h5>
                              </div> 
                              <div className="font-medium text-gray-900 pt-3 sm:flex sm:justify-between">
                                <h5>Price: ¥ {product.price}</h5>
                              </div>                                
                            </div>
                          </div>
  
                          <div className="mt-6 sm:flex sm:justify-between sr-only">
                            <div className="flex items-center">
                              {/* <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" /> */}
                              <p className="ml-2 text-sm font-medium text-gray-500">
                                Delivered on <time dateTime={orderDetail.created_at}>{orderDetail.created_at}</time>
                              </p>
                            </div>
  
                            {/* <div className="mt-6 border-t border-gray-200 pt-4 flex items-center space-x-4 divide-x divide-gray-200 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                              <div className="flex-1 flex justify-center">
                                <a
                                  // href={product.href}
                                  className="text-indigo-600 whitespace-nowrap hover:text-indigo-500"
                                >
                                  View product
                                </a>
                              </div>
                              <div className="flex-1 pl-4 flex justify-center">
                                <a href="#" className="text-indigo-600 whitespace-nowrap hover:text-indigo-500">
                                  Buy again
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </li>
                        
                      ))}
                    </ul>
                    <div className="flex  p-2 border-t border-gray-200  ">
                      <div className="flex  sm:items-start">      
                        <label className='text-gray-600'>Memo:</label>
                        <br/>
                        <label className='pl-2 '>{memo}</label>
                      </div>
                    </div>
                  </div>        
                  
              )}
                   
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


