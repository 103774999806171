import {validateToken} from "../../api/auth/validate";
import {
    storeTokenInLocalStorage,
    getTokenFromLocalStorage
} from "../../api/client";
import {fetchToken} from "../../api/auth/token";

import {Events} from "./events"
import {Context} from "./state-context"

const eventErrorType = (event: string, expected: string) => {
    throw new Error(
      `Wrong event type received. Event received is ${event} but was supposed to be ${expected}`
    );
};

export const validateTokenService = async (context: Context, event: Events) => {
    // console.log("Service > Validate token", event);
  
    const storageToken = getTokenFromLocalStorage();
  
    if (storageToken) {
      const result = await validateToken(storageToken.token);
  
      if (result && result.isValid) {
        return result;
      }
    }
  
    return Promise.reject("No token found.");
};

export const fetchTokenService = async (context: Context, event: Events) => {
    if (event.type !== "SUBMIT_LOGIN_CREDENTIALS") {
      return eventErrorType(event.type, "LOG_OUT");
    }
    console.log("Service > Fetch Token", event);
  
    let token;
  
    console.log("Fetch Token Service Event: ", event);
    const { uri, username, password } = event;
  
    if (!uri) {
      throw new Error(
        `Couldn't find a base URI. Please contact a system administrator.`
      );
    }
  
    try {
      token = await fetchToken(uri, username, password);
    } catch (error) {
      throw new Error(
        error?.message === null || error?.message === "Unauthorized"
          ? "Sorry, invalid username or password."
          : error.message
      );
    }
    console.log("isAdmin ", token.isAdmin);
    console.log("fetchTokenService", uri);
    if(token.isAdmin==true)
    {
      var isAdmin='yes';
    }else
    {
      var isAdmin='no';
    }
    storeTokenInLocalStorage(uri, token.access_token,isAdmin);
  /*
    try {
      const user = await validateToken(token.access_token);
      return user;
    } catch (error) {
      throw error;
    } */
};