import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { importLog } from '../../contracts/response-models/import-log';
import { Product } from '../../contracts/response-models/product';
import { DeleteProductService } from '../../machines/admin/services';
 
interface LogProps{
    logInfo: importLog; 
    setReadyState:any;
    setDisplayAlert:any;
    setAlertMessage:any;
    setLoading:any;
  }

function ImportLogRow({logInfo,setReadyState,setDisplayAlert,setAlertMessage,setLoading}:LogProps) { 
 
  const handleConfirmDelete = async (pid: any) => {
      /*
    setLoading(true);
    if (pid > 0) { 
      const rsp = await DeleteProductService(pid); 
      setDisplayAlert(true);
      setAlertMessage("Product Deleted Successfully.")
      setTimeout( () => {  setDisplayAlert(false) 
      } , 2000);
      
      setReadyState(true);
      setLoading(false);
    } */
  }
  
  return (
    <tr>
    <td className=" px-6 py-4 whitespace-nowrap text-sm text-gray-500">
     {logInfo.id} 
      </td>  
      <td className="px-6 py-4  whitespace-nowrap  text-sm text-gray-500">
       {logInfo.starttime}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> 
      {logInfo.endtime}
       </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {logInfo.status=="pending" &&(<p className="text-red-500 font-semibold">Pending</p>)}
      {logInfo.status=="processing" &&(<p className="text-orange-500 font-semibold">Processing</p>)}
      {logInfo.status=="completed" &&(<p className="text-green-500 font-semibold">Completed</p>)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        
  
      </td>
    </tr>
  );
}

export default ImportLogRow;
