import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { listCategoryService } from '../machines/admin/services';
import { PagedResult } from '../contracts/response-models/paged-result';
import { Category } from '../contracts/response-models/category';
import Loader from '../components/loader/loader';
import ProductRow from '../components/products/product-row';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Alert from '../components/alert/alert';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';
import CategoryRow from '../components/categories/category-row';

export const CategoryList = () => {  
 
  const [loading, setLoading] = useState(false); 
  const [categoryResult, setCategoryResult] = useState<PagedResult<Category>>();
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [readyState, setReadyState] = useState(true);
  const [paged, setPaged] = useState(1);
  const [perpagearecord, setPagePerRecord] = useState(10);
  const [sortedColumn, setSortedColumn] = useState('id');
  const [sortDirection, setSortDirection] = useState('desc');

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    async function loadCategories() {
      if (readyState == true) {
        setLoading(true);
        const productResult = await listCategoryService(paged, perpagearecord, sortedColumn, sortDirection);
        setCategoryResult(productResult)
        setCategories(productResult.data); 
        setLoading(false);
        setReadyState(false); 
      }
    }

    loadCategories();

  }, [readyState, paged, perpagearecord, sortedColumn, sortDirection]);
  


  const handlePageChange = async (pageNumber: number) => {
    window.scrollTo(0, 0)
  //  SetCurrentPage(pageNumber);
    let currentPage = pageNumber;
    setPaged(currentPage);
    setReadyState(true);

};

  return (
    
   
    <main className="flex-1 relative bg-white overflow-y-auto focus:outline-none" >
      <div className="py-6">
        <div className="  mx-auto px-4 sm:px-6 md:px-8">
        <Breadcrumb mainTitle="Series" mainLink="" childTitle="" childLink="" />        
      
          <h1 className="text-left text-2xl font-semibold text-gray-900">Series</h1>
        </div>
        <div className="flex justify-end mr-10"> 
        <Link to={'/add-series'} className="text-indigo-600 hover:text-indigo-900">
   
<svg className="h-6 w-6 mx-auto inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg> Add Series</Link></div>
        <div className=" mx-auto px-4 sm:px-6 md:px-8">
        {displayAlert &&(
      <Alert type="success" message={alertMessage} displayAlert={displayAlert} setDisplayAlert={setDisplayAlert}  />
    )}  
        {loading ? ( <div className="  mx-auto px-4 sm:px-6 md:px-8 text-center content-center	"> <Loader  /></div>) : (
          <div className="py-4">
           
            
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                         
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Title
                          </th>
                         
                          <th scope="col" className="hidden px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                      {categoryResult?.data?.map(function (categoryInfo, i) {
                        return (
                    <CategoryRow categoryInfo={categoryInfo} setReadyState={setReadyState}  setDisplayAlert={setDisplayAlert} setAlertMessage={setAlertMessage} setLoading={setLoading} /> 
                        )
                      })}
                         
                      
                      </tbody>
                    </table>
                     
                                  {categoryResult?.data!=undefined && (
                                                   <nav className="border-t border-gray-200 h-16 px-4 flex items-center justify-between sm:px-0">
                                                <div className="pl-3">Displaying {((categoryResult.pageIndex-1)*perpagearecord)+1} -   &nbsp;
                                                {categoryResult.hasNextPage ?(categoryResult.pageIndex*perpagearecord):(categoryResult.totalRecords)}  &nbsp; of &nbsp; {categoryResult.totalRecords} records</div>
                                               
                                               
                                                   <div className="-mt-px w-0 flex-1 flex flex justify-end pr-4">
                                                       <Pagination
                                                             itemClass="border-t-2 border py-2 pr-1 inline-flex items-center text-sm font-medium text-gray-500  hover:border-gray-600"
                                                             linkClass="text-center h-5 w-9 text-gray-400 hover:text-gray-800"
                                                             activeClass="active border-2 border border-gray-600"
                                                             activeLinkClass="active"
                                                             activePage={categoryResult?.pageIndex}
                                                             itemsCountPerPage={10}
                                                             totalItemsCount={categoryResult?.totalRecords}
                                                             pageRangeDisplayed={5}
                                                             onChange={handlePageChange}
                                                         />
                                                   </div>
                                                 </nav>
                                                            )}
                                                    
                  </div>
                </div>
              </div>
            </div>

           
          </div>
          )}
        </div>
      </div>
    </main>
  
  );
}

export default CategoryList;
