import React, { useEffect, useState } from 'react';
import { Product } from '../contracts/response-models/product';
import { Category } from '../contracts/response-models/category-list';

import { createProductService, editProductService, getCategoryListService, getProductInfoService } from '../machines/admin/services'
 
import { ValidationMessage } from '../components/validation-message/validation-message';
import NumberFormat from "react-number-format";
import Loader from '../components/loader/loader';
import Alert from '../components/alert/alert';
import { useParams } from "react-router";
import { useLocation } from 'react-router-dom';
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { baseUrl } from '../utils/base-url';
import { thumbUrl } from '../utils/thumb-url';
import Breadcrumb from '../components/layouts/breadcrumb/Breadcrumb';

interface imageInfo {
  id:number;
  fileName:string;
  fullUrl:string;
}

export const AddProduct = () => {  
  const [loading, setLoading] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isUploading,setIsUploading] = useState(false);
  const [imageUrlInfo,setImageUrlInfo]= useState<imageInfo[]>();
 
  const [isSavedSuccess, setSavedSuccess] = useState(false);
  const [product, setProduct] = useState<Product>({id:0});
  const [categories, setCategory] = React.useState<Category[]>([]);
  let id;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  if(query.get("id")){
    let idParam = query.get("id");
    id = idParam;
  }

//  let { id } = useParams();

  const [productid, setproductid] = useState<any>(id);
  
  useEffect(() => {    
    async function getProduct() {
      setLoading(true);
        if(productid){
            const productinfo = await getProductInfoService(productid);             
            setProduct(productinfo);  
            const categories = await getCategoryListService(); 
            setCategory(categories);
        }else{
          const categories = await getCategoryListService(); 
          setCategory(categories);
        }
        
      setLoading(false); 
    }
    getProduct();
},[id]);

useEffect(() => {    
  async function setimageUrl() {
    var imgArr = new Array<imageInfo>()
    product.image?.map((imageInfo, index) => {
      var itemid = index+1;
      imgArr?.push({
        id:itemid,
        fileName: imageInfo.toString(),
        fullUrl: baseUrl+"/storage/uploads/thumbnail/"+imageInfo.toString(), 
    });
    })
    if(imageUrlInfo?.length==0 || imageUrlInfo?.length == undefined)
    { 
      setImageUrlInfo(imgArr);
    }
   

  }
  setimageUrl();
},[product.image]);


  const saveProduct = async (e: any) => {

    e.preventDefault()
    var isValidated = true;
    if (product?.status == undefined || product.status == "") {
      setProduct({ ...product, status: '' });
      document.getElementById('status')?.focus();
      isValidated = false
    } 

    if (product?.description == undefined || product.description == "") {
      setProduct({ ...product, description: '' });
      document.getElementById('description')?.focus();
      isValidated = false

    }
    
    if (product?.price == undefined || product.price.toString() == "") {
      setProduct({ ...product, price: undefined });
      document.getElementById('price')?.focus();
      isValidated = false
    }
    if (product?.size == undefined || product.size == "") {
      setProduct({ ...product, size: '' });
      document.getElementById('size')?.focus();
      isValidated = false
    }
    if (product?.sku_id == undefined || product.sku_id == "") {
      setProduct({ ...product, sku_id: '' });
      document.getElementById('sku_id')?.focus();
      isValidated = false
    }
    if (product?.title == undefined || product.title == "") {
      setProduct({ ...product, title: '' });
      document.getElementById('title')?.focus();
      isValidated = false
    } 
      
    if (isValidated == true) {
      setLoading(true); 
        if(productid!='' && productid!=undefined)
        {
          const response = await editProductService(product,productid);
          setAlertMessage("Product Updated Successfully.");
        }else
        {
          
          const response = await createProductService(product);
          setAlertMessage("Product Added Successfully.");
        }
        

        setDisplayAlert(true);
        setTimeout( () => {  setDisplayAlert(false)
          window.location.href="/products";
          } , 1000);
         
        setTimeout( () => {  setDisplayAlert(false)
        
        } , 5000);
        setProduct({});
      setLoading(false);
      setSavedSuccess(true);
    }

  }

  const handlePhotoUploadChange = (e:any) =>{
    
    setIsUploading(true);
    const filesUpload = e.target.files;  
    const formData = new FormData()
    for(var i=0;i<filesUpload.length;i++)
    {
      formData.append('files', filesUpload[i])
    }
    uploadFiles(formData);
  }

  const uploadFiles=(formData:any)=>{
  // var url = "http://127.0.0.1:8000/api/upload";
     var url = "https://api.lihongart.com/api/upload";
    fetch(url, {  
      
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
    .then(data => {    
      console.log(data);
      var imageinfo = data.images;
      var filearray = [];
      var imageinfoarray = [];
      for(var i=0;i<imageinfo.length;i++)
      {
        filearray.push(imageinfo[i].fileName);
        if(imageUrlInfo!=undefined)
        {
          var itemid = imageUrlInfo?.length+i+1;
        }else
        {
          var itemid = i+1;
        }
       
        imageinfoarray.push({'id':itemid,'fileName':imageinfo[i].fileName,'fullUrl':thumbUrl+imageinfo[i].fileName});
      }     

      var emptyArr = new Array<string>()
      var arrPhotos = product.image == undefined ? emptyArr : product.image;
      arrPhotos = arrPhotos.concat(filearray);

      var emptyimageInfo = new Array<imageInfo>()
      var imagesInfo = imageUrlInfo == undefined ? emptyimageInfo : imageUrlInfo;
      imagesInfo = imagesInfo.concat(imageinfoarray);
      setImageUrlInfo(imagesInfo);
      setProduct({...product,image:arrPhotos});    
      setIsUploading(false); 
    })
    .catch(error => {
      console.error(error)
    }) 
  }

  const handleDragDropChange =(sortedList:any) =>{ 
    setImageUrlInfo(sortedList);
    let images = new Array; 
    sortedList?.map((object:any, i:number) => { 
      images?.push(object.fileName); 
    }) 
    setProduct({...product,image: images}); 
}

const handlePhotoDelete =(e:any,id:number) =>{
  e.preventDefault();
 // const index = property.image?.indexOf(e.target.value);
  let imagesInfo = imageUrlInfo;
  let updateimagesInfo = new Array;

  imagesInfo?.map((object, i) => {
    if(object.id!=id)
    { 
      updateimagesInfo?.push({
        id: object.id,
        fileName: object.fileName.toString(),
        fullUrl: thumbUrl+object.fileName.toString(),
    });
    }
  }) 
  setImageUrlInfo(updateimagesInfo);  
  let images = new Array;
  updateimagesInfo?.map((object, i) => { 
    images?.push(object.fileName); 
  }) 
  setProduct({...product,image: images}); 
  
}

  return (
    <>
    
    <main className="flex-1 bg-white relative overflow-y-auto focus:outline-none" >
   
      <div className="py-6">
        <div className=" mx-auto px-4 sm:px-6 md:px-8">
        <Breadcrumb mainTitle="Products" mainLink="/products" childTitle={productid!='' && productid!=undefined   ?("Edit Product"):("Add Product")} childLink="" />        
      
          <h1 className="text-left text-2xl font-semibold text-gray-900">{productid!='' && productid!=undefined   ?("Edit Product"):("Add Product")}</h1>
         
        </div>
        <div className=" mx-auto px-4 sm:px-6 md:px-8">
         
          <div className="py-4">
            
            {loading ? (<Loader  />) : (
              <div className="border-2 border-gray-200 rounded-lg ">
              
            <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        

        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5 ml-5 mr-5">
        {displayAlert &&(
      <Alert type="success" message={alertMessage} displayAlert={displayAlert} setDisplayAlert={setDisplayAlert}  />
    )}
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 text-left ">Product Information</h3>
 
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Product Name  
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="title" 
                  id="title"
                 value={product?.title}
                 onChange={(e)=> {
                  setProduct({ ...product, title: e.target.value });
                 }}
                  className="border-2 p-2   rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-red-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                <ValidationMessage isInvalid={(product.title == undefined) || (product.title != '') ? false : true} errorMessage="Please enter product name" />
              
              </div>
            </div>
          
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="status" className="text-left block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
               Series
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="category_id"
                  name="category_id"
                  value={product.category_id}
                  onChange={(e)=> {
                    setProduct({ ...product, category_id: parseInt(e.target.value) });
                    }}
                  className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"

                >             
                  <option value="">Select Series</option>
                  {categories?.map(function (catInfo, i) {
                        return (
                  <option value={catInfo.id}>{catInfo.title}</option>
                  )
                })}
                  

                </select>
                <ValidationMessage isInvalid={(product.status == undefined) || (product.status != '') ? false : true} errorMessage="Please select product status" />
              
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
               SKU  
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="sku_id"
                  id="sku_id" 
                  value={product?.sku_id}
                        onChange={(e)=> {
                         setProduct({ ...product, sku_id: e.target.value });
                        }}
                  className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                 <ValidationMessage isInvalid={(product.sku_id == undefined) || (product.sku_id != '') ? false : true} errorMessage="Please enter product sku" />
              
              </div>
            </div>
            <div className="sm:grid sr-only sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Meta Title
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="meta_title" 
                  value={product?.meta_title}
                 onChange={(e)=> {
                  setProduct({ ...product, meta_title: e.target.value });
                 }}
                  className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sr-only sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Meta Tag Keywords
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2"> 
              <textarea
                         
                         name="meta_tag_keywords"
                         rows={2}
                         value={product?.meta_tag_keywords}
                 onChange={(e)=> {
                  setProduct({ ...product, meta_tag_keywords: e.target.value });
                 }}
                         className="border-2 p-2 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                         placeholder=""
                         defaultValue={''}
                       />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sr-only sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Meta Tag Descriptions
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                         
                        name="meta_tag_description"
                        rows={2}
                        value={product?.meta_tag_description}
                        onChange={(e)=> {
                         setProduct({ ...product, meta_tag_description: e.target.value });
                        }}
                        className="border-2 p-2   rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />
              </div>
            </div>

         

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Carton spec
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="size" 
                  id="size" 
                  value={product?.size}
                  onChange={(e)=> {
                   setProduct({ ...product, size: e.target.value });
                  }}
                  className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
                  <ValidationMessage isInvalid={(product.size == undefined) || (product.size != '') ? false : true} errorMessage="Please enter product size" />
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              N.W.

              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <NumberFormat  
              value={product?.nw}
              onChange={(e:any)=> {
                var value = e.target.value;
                 if (e.target.value == '') {
                      value = undefined;
                  } 
               setProduct({ ...product, nw: value });
              }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={2}
          className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
        />

           
                  <ValidationMessage isInvalid={(product.nw == undefined) || (product.nw != '') ? false : true} errorMessage="Please enter product n.w." />
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              G.W. 
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <NumberFormat  
              value={product?.gw}
              onChange={(e:any)=> {
                var value = e.target.value;
                 if (e.target.value == '') {
                      value = undefined;
                  } 
               setProduct({ ...product, gw: value });
              }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={2}
          className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
        />

              
                  <ValidationMessage isInvalid={(product.gw == undefined) || (product.gw != '') ? false : true} errorMessage="Please enter product g.w." />
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Height
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">

              <NumberFormat  
              value={product?.height}
              onChange={(e:any)=> {
                var value = e.target.value;
                 if (e.target.value == '') {
                      value = undefined;
                  } 
               setProduct({ ...product, height: value });
              }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={2}
          className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
        />

                
                  <ValidationMessage isInvalid={(product.height == undefined) || (product.height != '') ? false : true} errorMessage="Please enter product height" />
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Cuft
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <NumberFormat  
              value={product?.cuft}
              onChange={(e:any)=> {
                var value = e.target.value;
                 if (e.target.value == '') {
                      value = undefined;
                  } 
               setProduct({ ...product, cuft: value });
              }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={2}
          className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
        />
            
                  <ValidationMessage isInvalid={(product.cuft == undefined) || (product.cuft != '') ? false : true} errorMessage="Please enter product cuft" />
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Point
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <NumberFormat  
              value={product?.point}
              onChange={(e:any)=> {
                var value = e.target.value;
                 if (e.target.value == '') {
                      value = undefined;
                  } 
               setProduct({ ...product, point: value });
              }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={2}
          className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
        />
            
                  <ValidationMessage isInvalid={(product.point == undefined) || (product.point != '') ? false : true} errorMessage="Please enter product point" />
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Product Price (CNY)
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <NumberFormat  
              value={product?.price}
              onChange={(e:any)=> {
                var value = e.target.value;
                 if (e.target.value == '') {
                      value = undefined;
                  } 
               setProduct({ ...product, price: value });
              }}
          allowNegative={false}
          thousandSeparator={false}
          decimalScale={0}
          className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" 
        />

             
                  <ValidationMessage isInvalid={(product.price == undefined) || (product.price.toString() != '') ? false : true} errorMessage="Please enter product price" />
              
              </div>
            </div>

             <div className="text-left sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_photo" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Product Images
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div  
                className="max-w-lg flex  d px-6  border-0 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400 hidden"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span className="text-left">Upload Image</span>
                        <input id="file-upload" name="file-upload" multiple type="file"
                         onChange={handlePhotoUploadChange}
                        className="sr-only" />
                      </label>
                      <p className="pl-1 hidden">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG, GIF files only</p>
                    <p className="text-xs text-gray-500">  {isUploading && ("Uploading...")}  </p>


                   

                  
                  </div>
                </div>
              </div>
            </div>
         
                     {imageUrlInfo!=undefined && imageUrlInfo.length>0 && (
   <div className="mx-auto max-w-md	 py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
   <div className="space-y-12">
     
     <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">

          <RLDD
  items={imageUrlInfo}
  layout="horizontal"
  itemRenderer={(item:imageInfo,index) => {
    return ( 
      
      <li className="m-2" >
                        <div className="space-y-4">
                        
                          <div className="aspect-w-1 aspect-h-1 ">
                            <div className="pl-44"  >
                              <svg xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer'}}   onClick={(e)=> { handlePhotoDelete(e,item.id)}} className="h-6 w-6 hover:bg-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                            <img className="object-cover shadow-lg rounded-lg h-40 w-52" width="150" height="150" src={item.fullUrl.toString()}/>
                          </div>
                        </div>
                      </li>
      
  
    );
  }}
  onChange={handleDragDropChange}

  
/>
</ul>
                  </div>
                </div>  


)}   
                      
                     
                     
                     
                
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
               Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                         
                        name="description"
                        rows={5}
                        id="description" 
                        value={product?.description}
                        onChange={(e)=> {
                        setProduct({ ...product, description: e.target.value });
                        }}
                        className="border-2 p-2   rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />
                       <ValidationMessage isInvalid={(product.description == undefined) || (product.description != '') ? false : true} errorMessage="Please enter product description" />
              
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="product_name" className="block text-left text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
               English description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                         
                        name="description"
                        rows={5}
                        id="description" 
                        value={product?.description_english}
                        onChange={(e)=> {
                        setProduct({ ...product, description_english: e.target.value });
                        }}
                        className="border-2 p-2   rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        defaultValue={''}
                      />                      
              
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="status" className="text-left block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
               Status
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  id="status"
                  name="status"
                  value={product.status}
                  onChange={(e)=> {
                    setProduct({ ...product, status: e.target.value });
                    }}
                  className="border-2 p-2  rounded-lg max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"

                >
             
                  <option value="3">Select Status</option>
                  <option value="1">Active</option>
                  <option value="2">Deactive</option>
                </select>
                <ValidationMessage isInvalid={(product.status == undefined) || (product.status != '') ? false : true} errorMessage="Please select product status" />
              
              </div>
            </div>


          </div>
        </div>

      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={()=>{ window.location.href="/products"}}
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={saveProduct}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-2"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    </div>
      )}
          
          </div>
         
        </div>
      </div>
    </main>
  </>
  );
}

export default AddProduct;
